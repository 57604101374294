<script setup lang="ts">
import { format } from 'date-fns'
import { de } from 'date-fns/locale'
import { FieldParagraphStickyEventTeaserFieldEventTag } from '~/api/graphql/generated'
import { useStickyEvent } from '~/api/drupal'

const props = defineProps<{
  title: string
  ctaLabel: string
  eventItem: FieldParagraphStickyEventTeaserFieldEventTag | null
}>()

const { eventItem } = await useStickyEvent(props.eventItem)
const isVisible = ref(true)
const formattedDate = computed(() =>
  eventItem && eventItem.value
    ? format(eventItem.value.date, 'EEEE, d. LLLL yyyy,', {
        locale: de,
      })
    : '',
)
</script>

<template>
  <div v-if="eventItem" class="sticky-event-teaser" :class="{ hide: !isVisible }">
    <button class="close" type="button" @click="isVisible = !isVisible">
      <Icon name="mini-close-medium" />
    </button>
    <h5 class="title">{{ props?.title || eventItem?.title }}</h5>

    <p class="info text-xsmall">
      <span>{{ formattedDate }}</span>
      <span>{{ eventItem?.time }}</span>
    </p>

    <NuxtLink class="teaser-link link-default" :to="eventItem?.link?.url" :target="eventItem?.link?.target">
      {{ ctaLabel }}
    </NuxtLink>
  </div>
</template>

<style scoped lang="scss">
.sticky-event-teaser {
  display: block;
  background: #f1faff;
  box-shadow: 0px 20px 50px rgba(10, 21, 92, 0.15);
  font-size: 14px;
  padding: 18px 24px;
  margin-bottom: 30px;

  &.hide {
    display: block;
  }

  .close {
    display: none;
  }

  .title {
    margin-bottom: 8px;
  }
  .info {
    margin-bottom: 0;
    span {
      display: block;
    }
  }

  .teaser-link {
    display: inline-block;
    margin-top: 15px;
  }

  @include breakpoint(tl) {
    position: fixed;
    right: 30px;
    bottom: 210px;
    z-index: 20;
    font-size: 16px;
    padding: 32px;

    max-width: 320px;
    &.hide {
      display: none;
    }

    .close {
      display: block;
      cursor: pointer;
      position: absolute;
      line-height: 24px;
      top: 14px;
      right: 14px;
      width: 12px;
      height: 12px;
    }
  }
}
</style>
